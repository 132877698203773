import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../../../Context";
import ReactLoading from "react-loading";
import { useOktaAuth } from "@okta/okta-react";
import { ProgressCircleContainer } from "../../../../../../Components";
import {
  answerThirdPartyComment,
  answerThirdPartyResponse,
  downloadExport,
} from "../../../../../GlobalResources/ApiCalls";
const XLSX = require("xlsx");

export const ImportExport: React.FC<any> = (props: any) => {
  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<Array<any>>([]);
  const { globalState } = useContext(AppContext);
  const { authState } = useOktaAuth();

  const handleSubmit = (e: any) => {
    switch (e.target.innerText) {
      case "Export":
        getExport();
        break;
      case "Import":
        window.confirm(
          "Are you sure you want to import this bpq?\nAny out-of-scope questions or questions without Third Party Responses or Comments will be ignored.\nPlease allow the import to complete before leaving this page."
        ) && importFile();
        break;
    }
  };

  const importFile = async () => {
    setIsImporting(true);
    sessionStorage.setItem("isImporting", "true");
    window.dispatchEvent(new Event("storage"));

    try {
      // @ts-ignore
      for (const row of selectedFile.values()) {
        if (row["In-Scope"] !== "Out of Scope") {
          if (row["Third Party Response"]) {
            const payload = {
              // @ts-ignore
              serialId: row["Serial ID"],
              key: "mainAnswer",
              value: String(row["Third Party Response"]),
            };

            await answerThirdPartyResponse(globalState, authState, payload);
          }

          if (row["Third Party Comment"]) {
            const payload = {
              // @ts-ignore
              serialId: row["Serial ID"],
              key: "additionalComments",
              value: String(row["Third Party Comment"]),
            };

            await answerThirdPartyComment(globalState, authState, payload);
          }
        }
      }

      setTimeout(function () {
        alert("Bpq imported successfully!");
        setIsImporting(false);
        sessionStorage.setItem("isImporting", "false");
        window.dispatchEvent(new Event("storage"));
      }, 1000);
    } catch (err) {
      globalState.featureToggle.debugLogging && console.error(err);
      globalState.featureToggle.debugLogging && console.log(err.response);
      setIsImporting(false);
      sessionStorage.setItem("isImporting", "false");
      window.dispatchEvent(new Event("storage"));
      alert(globalState.config.errorMessage);
    }
  };

  const getExport = async () => {
    try {
      setIsExporting(true);
      await downloadExport(globalState, authState);
      setIsExporting(false);
    } catch (err) {
      globalState.featureToggle.debugLogging && console.error(err);
      globalState.featureToggle.debugLogging && console.log(err.response);
      setIsExporting(false);
      alert(globalState.config.errorMessage);
    }
  };

  const handleChange = async (e: any) => {
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(e.target.files[0]);
    fileReader.onload = (e) => {
      let data = e?.target?.result;
      let workbook = XLSX.read(data, { type: "binary" });
      let excelJSON = XLSX.utils.sheet_to_json(
        workbook.Sheets["Custom Export"] || workbook.Sheets["BPQ Export"]
      );
      setSelectedFile(excelJSON);
    };
  };

  useEffect(() => {
    function checkIsImporting() {
      const isImporting = sessionStorage.getItem("isImporting");

      if (isImporting === "true") {
        setIsImporting(true);
      } else {
        setIsImporting(false);
      }
    }

    window.addEventListener("storage", () => {
      checkIsImporting();
    });

    window.dispatchEvent(new Event("storage"));

    return () => {
      sessionStorage.setItem("isImporting", "false");
      window.removeEventListener("storage", checkIsImporting);
    };
  }, []);

  return (
    <div>
      {globalState.featureToggle.exportBpq && (
        <div>
          <h3>Export BPQ</h3>
          {isExporting === false ? (
            <button onClick={handleSubmit}>Export</button>
          ) : (
            <ReactLoading
              type={"spin"}
              color={"rgb(227, 230, 255)"}
              height={"10%"}
              width={"10%"}
            />
          )}
        </div>
      )}

      {globalState.featureToggle.importBpq && !isExporting && (
        <div>
          <label>
            <h3>Import BPQ</h3>
            <input
              type="file"
              name="file"
              onChange={handleChange}
              accept=".xlsx"
            />
          </label>
          {isImporting === false && selectedFile.length > 0 ? (
            <button onClick={handleSubmit}>Import</button>
          ) : isImporting === false && selectedFile.length === 0 ? (
            <React.Fragment></React.Fragment>
          ) : (
            <React.Fragment>
              <button>Importing...</button>
              <ProgressCircleContainer
                text={"Importing Third Party Responses and Comments"}
              />
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default ImportExport;
