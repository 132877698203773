import axios from "axios";

export const getAssessmentBpq = async (globalState: any, authState: any) => {
  if (
    globalState.permissions["Assessment.Bpq.View"] &&
    globalState.assessmentData
  ) {
    try {
      const res = await axios.get(
        `${globalState.config.portalApiUrl}/assessments/${globalState.selectedAssessmentId}/bpq`,
        {
          headers: {
            authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }
      );

      return {
        assessmentBpq: res.data.questions,
      };
    } catch (err) {
      globalState.featureToggle.debugLogging && console.error(err);
      globalState.featureToggle.debugLogging && console.log(err.response);
      alert(globalState.config.errorMessage);
    }
  } else {
    return;
  }
};
