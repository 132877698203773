import React, { useState, useContext, useEffect } from "react";
import "./AssessmentOptions.css";
import { useOktaAuth } from "@okta/okta-react";
import ReactLoading from "react-loading";
import ImportExport from "./Options/ImportExport";
import RepublishAllComments from "./Options/RepublishAllComments";
import UserAssignments from "./Options/UserAssignments/UserAssignments";
import GenerateCache from "./Options/GenerateCache";
import { AppContext } from "../../../../../Context";

import { getAssessmentBpq } from "../../../../GlobalResources/ApiCalls";

export const AssessmentOptions: React.FC<any> = (props) => {
  const [option, setOption]: any = useState("");
  const { globalState, setGlobalState } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const { authState } = useOktaAuth();

  const handleChange = (e: any) => {
    setOption(e.target.value);
  };

  useEffect(() => {
    let unMounted = false;
    const onLoad = async () => {
      const bpqAndMapping = await getAssessmentBpq(globalState, authState);
      !unMounted &&
        setGlobalState({
          ...globalState,
          assessmentBpq: bpqAndMapping?.assessmentBpq,
        });
      setLoading(false);
    };
    globalState.selectedAssessmentId && onLoad();

    const isImporting = sessionStorage.getItem("isImporting");

    if (isImporting === "true") {
      setOption("importExport");
    }
    return () => {
      unMounted = true;
    };
  }, [globalState.selectedAssessmentId]);

  return loading ? (
    <React.Fragment>
      <div id="AssessmentOptions">
        <h3>Options</h3>
        <ReactLoading
          type={"bars"}
          color={"rgb(227, 230, 255)"}
          height={"20%"}
          width={"20%"}
        />
      </div>
    </React.Fragment>
  ) : (
    <div id="AssessmentOptions">
      <h3>Options</h3>
      {!globalState.assessmentBpq && <h4>No BPQ exists for this assessment</h4>}
      {option !== "" && (
        <button onClick={handleChange} value="">
          Back
        </button>
      )}
      {globalState.permissions["Assessment.Bpq.Export"] &&
      globalState.assessmentBpq &&
      option === "" ? (
        <button onClick={handleChange} value="importExport">
          Import & Export
        </button>
      ) : (
        option === "" && (
          <button className="disabled" disabled={true}>
            Import & Export
          </button>
        )
      )}

      {globalState.permissions["Assessment.People.View"] && option === "" ? (
        <button onClick={handleChange} value="userAssignment">
          User Assignment
        </button>
      ) : (
        option === "" && (
          <button className="disabled" disabled={true}>
            User Assignment
          </button>
        )
      )}

      {option === "" && <RepublishAllComments />}
      {option === "" && <GenerateCache />}

      {option === "importExport" &&
        globalState.permissions["Assessment.Bpq.Export"] && <ImportExport />}
      {option === "userAssignment" &&
        globalState.permissions["Assessment.People.View"] && (
          <UserAssignments />
        )}
    </div>
  );
};

export default AssessmentOptions;
