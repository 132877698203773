import React, { useContext, useState } from "react";
import "./OrdersManagement.css";
import { AppContext } from "../../../Context";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import ReactLoading from "react-loading";

export const OrdersManagement: React.FC<any> = () => {
  const { globalState } = useContext(AppContext);
  const { authState } = useOktaAuth();
  const [orderNumber, setOrderNumber] = useState("");
  const [isLoading, setIsLoading]: any = useState(false);
  const [cancelationType, setCanceletionType] = useState('"TsCanceled"');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(
        `${globalState.config.portalApiUrl}/orders/${orderNumber}/cancel`,
        cancelationType,
        {
          headers: {
            authorization: `Bearer ${authState?.accessToken?.accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      alert("Order cancelled successfully!");
      setIsLoading(false);
    } catch (err) {
      // Handle Error Here
      if (err.response.status === 400) {
        alert(
          "Unable to cancel this order.  The order is likely already approved or canceled."
        );
        setIsLoading(false);
        globalState.featureToggle.debugLogging && console.error(err);
        globalState.featureToggle.debugLogging && console.log(err.response);
      } else {
        alert(globalState.config.errorMessage);
        setIsLoading(false);
        globalState.featureToggle.debugLogging && console.error(err);
        globalState.featureToggle.debugLogging && console.log(err.response);
      }
    }
  };

  const handleChange = (e: any) => {
    if (e.target.name === "OrderNumber") {
      setOrderNumber(e.target.value);
    }
    if (e.target.name === "type") {
      setCanceletionType(e.target.value);
    }
  };

  return globalState.featureToggle.cancelOrder ? (
    <div id="OrdersManagement">
      <h1>Orders Management</h1>
      {!isLoading ? (
        <div id="InputBoxContainer">
          <div id="InputBox">
            <h3>Input the order you wish to cancel here:</h3>
            <form>
              <label htmlFor="OrderNumber">Order Number:</label>
              <input
                type="text"
                id="OrderNumber"
                name="OrderNumber"
                value={orderNumber}
                onChange={handleChange}
              />
              <select title="select" name="type" id="type" onChange={handleChange}>
                <option value='"TsCanceled"'>TruSight Cancelled</option>
                {/* Customer canceled option disabled due to request optimizer bug with CusCanceled orders */}
                {/* <option value='"CusCanceled"'>Customer Canceled</option> */}
              </select>
              <button onClick={handleSubmit}>Submit</button>
            </form>
          </div>
        </div>
      ) : (
        <div id="AssessmentLoadingContainer">
          <ReactLoading
            type={"spin"}
            color={"rgb(227, 230, 255)"}
            height={"25%"}
            width={"25%"}
          />
        </div>
      )}
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default OrdersManagement;
