import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../../../../../Context";
import { useOktaAuth } from "@okta/okta-react";
// @ts-ignore
import Select from "react-select";
import {
  assignUserToAssessment,
  assignThirdPartyContact,
  unAssignThirdPartyContact,
  getAssessmentData,
  getAssessmentUsers,
  getAssignableUsers,
} from "../../../../../../../GlobalResources/ApiCalls";

export const AssignUser: React.FC<any> = (props: any) => {
  const [selectedUserToAssignAccess, setSelectedUserToAssignAccess] =
    useState<any>({});
  const [
    selectedThirdPartyToAssignContact,
    setSelectedThirdPartyToAssignContact,
  ] = useState<any>({});
  const { globalState, setGlobalState } = useContext(AppContext);
  const { authState } = useOktaAuth();
  let unMounted = false;

  const handleChange = (opt: any) => {
    if (opt.value.type === "Assign Assessment Access") {
      setSelectedUserToAssignAccess(opt.value);
    }

    if (opt.value.type === "Assign Third Party Contact") {
      setSelectedThirdPartyToAssignContact(opt.value);
    }
  };

  const handleSubmit = async (e: any) => {
    if (e.target.innerText === "Assign User") {
      await assignUserToAssessment(
        globalState,
        authState,
        selectedUserToAssignAccess
      );
    }

    if (e.target.innerText === "Assign Third Party Contact") {
      await assignThirdPartyContact(
        globalState,
        authState,
        selectedThirdPartyToAssignContact
      );
    }

    if (e.target.innerText === "Unassign Third Party Contact") {
      const thirdPartyContactToUnassign = globalState.assessmentUsers.filter(
        (user: any) =>
          user.fullName === globalState.assessmentData.thirdPartyAdminName
      )[0];
      await unAssignThirdPartyContact(
        globalState,
        authState,
        thirdPartyContactToUnassign
      );
    }

    const assessmentUsers = await getAssessmentUsers(globalState, authState);
    const assessmentData = await getAssessmentData(globalState, authState);
    const assignableUsers = await getAssignableUsers(globalState, authState);

    !unMounted &&
      setGlobalState({
        ...globalState,
        assessmentUsers,
        assessmentData,
        assignableUsers,
      });
  };

  useEffect(() => {
    return () => {
      unMounted = true;
    };
  }, []);

  return (
    <React.Fragment>
      {globalState.permissions["Assessment.People.Edit.TS"] &&
        globalState.featureToggle.assignUser && (
          <React.Fragment>
            <h3>Assign New User</h3>
            <Select
              onChange={(opt: any) => handleChange(opt)}
              options={globalState.assignableUsers}
            />

            <button onClick={handleSubmit}>Assign User</button>
          </React.Fragment>
        )}

      {globalState.permissions["Assessment.People.Edit.TP"] && (
        <React.Fragment>
          {globalState.featureToggle.assignThirdPartyContact && (
            <React.Fragment>
              <h3>Assign Third Party Admin Contact</h3>
              <Select
                onChange={(opt: any) => handleChange(opt)}
                options={globalState.assignableThirdPartyUsers}
              />

              <button onClick={handleSubmit}>Assign Third Party Contact</button>
            </React.Fragment>
          )}
          {globalState.assessmentData.thirdPartyAdminName !== "" &&
            globalState.featureToggle.unassignThirdPartyContact && (
              <button onClick={handleSubmit}>
                Unassign Third Party Contact
              </button>
            )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default AssignUser;
