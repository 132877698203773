import React, { useContext, useEffect } from "react";
import { AppContext } from "./Context";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";

const config = {
  portalApiUrl: process.env.REACT_APP_API_URL,
  errorMessage: process.env.REACT_APP_ERROR_MESSAGE,
  unauthorizedMessage: process.env.REACT_APP_UNAUTHORIZED_MESSAGE,
};

/*
 *Name : Feature Toggle
 *Description : Allows ability to turn off certain features 'on' or 'off'
 */
const featureToggle = {
  exportBpq:
    process.env.REACT_APP_FEATURE_TOGGLE_EXPORT_BPQ === "false" ? false : true,
  importBpq:
    process.env.REACT_APP_FEATURE_TOGGLE_IMPORT_BPQ === "false" ? false : true,
  assignUser:
    process.env.REACT_APP_FEATURE_TOGGLE_ASSIGN_USER === "false" ? false : true,
  unassignUser:
    process.env.REACT_APP_FEATURE_TOGGLE_UNASSIGN_USER === "false"
      ? false
      : true,
  cancelOrder:
    process.env.REACT_APP_FEATURE_TOGGLE_CANCEL_ORDER === "false"
      ? false
      : true,
  republishAllComments:
    process.env.REACT_APP_FEATURE_REPUBLISH_ALL_COMMENTS === "false"
      ? false
      : true,
  debugLogging:
    process.env.REACT_APP_FEATURE_DEBUG_LOGGING === "true" ? true : false,
  assignThirdPartyContact:
    process.env.REACT_APP_FEATURE_ASSIGN_THIRD_PARTY_CONTACT === "false"
      ? false
      : true,
  unassignThirdPartyContact:
    process.env.REACT_APP_FEATURE_UNASSIGN_THIRD_PARTY_CONTACT === "false"
      ? false
      : true,
};

export const SetGlobalState: React.FC<any> = (props: any) => {
  const { globalState, setGlobalState } = useContext(AppContext);
  const { authState } = useOktaAuth();

  const getPermissions = async () => {
    try {
      const res = await axios.get(`${config.portalApiUrl}/permissions`, {
        headers: {
          authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      });
      let newPermissions = {};

      res.data.forEach((permission: any) => {
        const permissionStage = permission.stage;
        // @ts-ignore
        if (!newPermissions[permission.permission]) {
          // @ts-ignore
          newPermissions[permission.permission] = {
            stages: {},
            relationshipToAssessment: permission.relationshipToAssessment,
          };
        if (permissionStage !== null)
        {
          //@ts-ignore
          newPermissions[permission.permission].stages[permissionStage] = true;
        }
        } else {
          if (permissionStage !== null){
            // @ts-ignore
            newPermissions[permission.permission].stages[permissionStage] = true;
        }
      }
      });

      setGlobalState({
        ...globalState,
        permissions: newPermissions,
      });
    } catch (err) {
      // Handle Error Here
      globalState.featureToggle.debugLogging && console.error(err);
      globalState.featureToggle.debugLogging && console.log(err.response);
      alert(config.errorMessage);
    }
  };

  useEffect(() => {
    setGlobalState({ config: config, featureToggle: featureToggle });

    authState && authState?.accessToken?.accessToken && getPermissions();
    authState &&
      authState?.accessToken?.accessToken &&
      // @ts-ignore
      sessionStorage.setItem("token", authState.accessToken.accessToken);
  }, [authState]);
  return <React.Fragment></React.Fragment>;
};

export default SetGlobalState;
