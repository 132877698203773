import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../../../../../Context";
import "./AssessmentUsersTable.css";
import { useOktaAuth } from "@okta/okta-react";
import {
  getAssignableUsers,
  getAssessmentUsers,
  getAssessmentData,
  unassignUserFromAssessment,
} from "../../../../../../../GlobalResources/ApiCalls";
import { IAssessmentUser } from "../../../../../../../GlobalResources/Interfaces";

export const AssessmentUsersTable: React.FC<any> = (props: any) => {
  const [checkedUsers, setCheckedUsers]: any = useState({});
  const { globalState, setGlobalState } = useContext(AppContext);
  const { authState } = useOktaAuth();
  let unMounted = false;

  const handleCheckboxChange = (e: any) => {
    let selectedUser = e.target.value;
    let checked = e.target.checked;

    setCheckedUsers({ ...checkedUsers, [selectedUser]: checked });
  };

  const handleSubmit = async () => {
    if (
      window.confirm("Are you sure you want to unassign the selected users?")
    ) {
      const checkedUsersKeys = Object.keys(checkedUsers);
      checkedUsersKeys.map(async (checkedUser) => {
        if (checkedUsers[checkedUser]) {
          const checkedUserObject: IAssessmentUser = JSON.parse(checkedUser);
          try {
            unassignUserFromAssessment(
              globalState,
              authState,
              checkedUserObject.userId
            );
          } catch (err) {
            // Handle Error Here
            globalState.featureToggle.debugLogging && console.error(err);
            globalState.featureToggle.debugLogging && console.log(err.response);
            alert(globalState.config.errorMessage);
          }
        }
      });

      setCheckedUsers({});
      const checkboxes = document.getElementsByTagName("input");
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type === "checkbox") {
          checkboxes[i].checked = false;
        }
      }

      const assignableUsers = await getAssignableUsers(globalState, authState);
      const assessmentData = await getAssessmentData(globalState, authState);
      const assessmentUsers = await getAssessmentUsers(globalState, authState);

      !unMounted &&
        setGlobalState({
          ...globalState,
          assessmentUsers,
          assignableUsers,
          assessmentData,
        });
    }
  };

  useEffect(() => {
    return () => {
      unMounted = true;
    };
  }, []);

  return (
    <React.Fragment>
      <h3>User Assignments</h3>
      <div className="tableContainer">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Portal Role</th>
            </tr>
          </thead>
          <tbody>
            {globalState.assessmentUsers.map(
              (user: IAssessmentUser, idx: number) => {
                return (
                  <tr key={idx} className={idx % 2 === 0 ? "altColor" : ""}>
                    <td>{user.fullName}</td>
                    <td>{user.role}</td>
                    <td>
                      {user.role !== "Third party admin" &&
                        !user.email.includes("trusight") &&
                        globalState.permissions["Assessment.People.Edit.TP"] &&
                        globalState.featureToggle.unassignUser && (
                          <input
                            type="checkbox"
                            id={user.userId}
                            name="user"
                            value={JSON.stringify(user)}
                            checked={checkedUsers.userId}
                            onChange={handleCheckboxChange}
                          />
                        )}
                      {user.role !== "Third party admin" &&
                        user.email.includes("trusight") &&
                        globalState.permissions["Assessment.People.Edit.TS"] &&
                        globalState.featureToggle.unassignUser && (
                          <input
                            type="checkbox"
                            id={user.userId}
                            name="user"
                            value={JSON.stringify(user)}
                            checked={checkedUsers.userId}
                            onChange={handleCheckboxChange}
                          />
                        )}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>

      {(globalState.permissions["Assessment.People.Edit.TS"] ||
        globalState.permissions["Assessment.People.Edit.TP"]) &&
        globalState.featureToggle.unassignUser && (
          <button onClick={handleSubmit}>Unassign Selected Users</button>
        )}
    </React.Fragment>
  );
};

export default AssessmentUsersTable;
