import axios from "axios";

export const getAllAssessments = async (globalState: any, authState: any) => {
  try {
    const res = await axios.get(
      `${globalState.config.portalApiUrl}/assessments`,
      {
        headers: {
          authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      }
    );

    return res.data;
  } catch (err) {
    // Handle Error Here
    globalState.featureToggle.debugLogging && console.error(err);
    globalState.featureToggle.debugLogging && console.log(err.response);
    alert(globalState.config.errorMessage);
  }
};
