import React, { useState } from "react";
import {
  Navbar,
  NotFound,
  AssessmentsManagement,
  OrdersManagement,
  AssessmentOverlay,
} from "./Components";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { SecureRoute } from "@okta/okta-react";
import { AppContext } from "./Context.js";
import SetGlobalState from "./SetGlobalState";

const CALLBACK_PATH = "/login/callback";

const oktaConfig = {
  clientId: `${process.env["REACT_APP_OKTA_CLIENT_ID"]}`,
  issuer: `${process.env["REACT_APP_OKTA_ISSUER"]}`,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ["openid", "profile", "email", "offline_access"],
  pkce: true,
};

const oktaAuth = new OktaAuth(oktaConfig);

const Routes: React.FC<any> = () => {
  const [globalState, setGlobalState]: any = useState(null);

  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AppContext.Provider value={{ globalState, setGlobalState }}>
        {globalState && globalState.permissions && <Navbar />}
        <Route component={SetGlobalState} />
        <Switch>
          <Route path={CALLBACK_PATH} component={LoginCallback} />
          <SecureRoute
            exact
            path="/"
            render={(props: any) => <Redirect to="/assessmentsmanagement" />}
          />
          <SecureRoute
            exact
            path="/assessmentsmanagement"
            render={(props: any) =>
              globalState.permissions && <AssessmentsManagement />
            }
          />
          <SecureRoute
            path="/assessmentsmanagement/:id"
            render={(props: any) =>
              globalState.permissions && <AssessmentOverlay />
            }
          />
          <SecureRoute
            path="/ordersmanagement"
            render={(props: any) =>
              globalState.permissions &&
              globalState.permissions["Assessment.Orders.Cancel.Full"] &&
              globalState.featureToggle.cancelOrder ? (
                <OrdersManagement />
              ) : (
                <h1>{globalState.config.unauthorizedMessage}</h1>
              )
            }
          />
          <Route path="/404" render={(props: any) => <NotFound />} />
          <Redirect to="/404" />
        </Switch>
      </AppContext.Provider>
    </Security>
  );
};

export default Routes;
