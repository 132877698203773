import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "./ProgressBarContainer.css";

export const ProgressBarContainer: React.FC<any> = (props: any) => {
  return (
    <div id="ProgressBarContainer">
      <div id="ProgressBarBox">
        <div id="ProgressBarBar">
          <ProgressBar
            completed={props.progress}
            bgColor={"#34e0e0"}
            labelColor={"rgb(59, 42, 42)"}
            transitionDuration={"0.1s"}
          />
        </div>

        {props.text && (
          <div id="ProgressBarText">
            <h1>{props.text}</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressBarContainer;
