import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../../../../Context";
import { useOktaAuth } from "@okta/okta-react";
import "./UserAssignments.css";
import AssessmentUsersTable from "./AssessmentUsersTable/AssessmentUsersTable";
import AssignUser from "./AssignUser/AssignUser";
import ReactLoading from "react-loading";
import {
  getAssignableUsers,
  getAssessmentUsers,
} from "../../../../../../GlobalResources/ApiCalls";

export const UserAssignments: React.FC<any> = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const { globalState, setGlobalState } = useContext(AppContext);
  const { authState } = useOktaAuth();

  useEffect(() => {
    let unMounted = false;
    const onLoad = async () => {
      const assessmentUsers = await getAssessmentUsers(globalState, authState);
      const assignableUsers = await getAssignableUsers(globalState, authState);
      const assignableThirdPartyUsers = assessmentUsers
        .filter((user: any) => user.role === "Third party admin")
        .map((user: any) => {
          return {
            label: `${user.fullName} - ${user.email}`,
            value: {
              userId: user.userId,
              userEmail: user.email,
              type: "Assign Third Party Contact",
            },
          };
        });

      !unMounted &&
        setGlobalState({
          ...globalState,
          assessmentUsers,
          assignableUsers,
          assignableThirdPartyUsers,
        });

      setIsLoading(false);
      return () => {
        unMounted = true;
      };
    };
    onLoad();
  }, []);

  return !isLoading ? (
    <div id="UserAssignments">
      {globalState.permissions["Assessment.People.View"] && (
        <AssessmentUsersTable />
      )}

      <AssignUser />
    </div>
  ) : (
    <ReactLoading
      type={"bars"}
      color={"rgb(227, 230, 255)"}
      height={"20%"}
      width={"20%"}
    />
  );
};

export default UserAssignments;
