export const assessmentStages: any = {
    48: 14,
    49: 15,
    50: "SD. Scale-Down Assessment",
    54: 4,
    55: 5,
    56: 6,
    57: 7,
    58: 8,
    59: 9,
    60: 10,
    61: 11,
    62: 12,
    63: 13,
    7014: 14,
    7015: 1,
    7016: 2,
    7017: 3,
}