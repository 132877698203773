import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Switch } from "react-router-dom";
import "./AssessmentsManagement.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useOktaAuth } from "@okta/okta-react";
import { AppContext } from "../../../Context";
import ReactLoading from "react-loading";
import { getAllAssessments } from "../../GlobalResources/ApiCalls";

export const AssessmentsManagement: React.FC<any> = () => {
  const { authState } = useOktaAuth();
  const [gridApi, setGridApi]: any = useState(null);
  const [gridColumnApi, setGridColumnApi]: any = useState(null);
  const [isLoading, setIsLoading]: any = useState(true);
  const [rowData, setRowData]: any = useState(null);
  const history = useHistory();
  const location = useLocation();

  const { globalState, setGlobalState } = useContext(AppContext);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    const updateData = (data: any) => {
      setRowData(data);
    };

    updateData(globalState.allAssessments);
  };

  const handleClick = () => {
    setGlobalState({
      ...globalState,
      selectedAssessmentId: null,
      assessmentBpq: null,
      assessmentData: null,
      questionsMapping: null,
      assessmentUsers: null,
      assignableUsers: null,
      assignableThirdPartyUsers: null,
    });
    gridApi.getSelectedRows();
    history.push(`/assessmentsmanagement/${gridApi.getSelectedRows()[0].id}`);
  };

  useEffect(() => {
    const onload = async () => {
      const allAssessments = await getAllAssessments(globalState, authState);

      setGlobalState({ ...globalState, allAssessments });
      setIsLoading(false);
    };
    onload();
  }, []);

  const colDefs = [
    { field: "organizationName" },
    { field: "id" },
    { field: "name" },
    { field: "statusName" },
    { field: "type" },
    { field: "version" },
  ];

  return (
    <React.Fragment>
      <div id="AssessmentsManagement">
        <h1>Assessments</h1>
        <div
          className="ag-theme-alpine"
          style={{ height: "90%", width: "90%" }}
        >
          {!isLoading ? (
            <AgGridReact
              rowSelection={"single"}
              onGridReady={onGridReady}
              rowData={rowData}
              pagination={true}
              paginationPageSize={50}
              defaultColDef={{
                flex: 1,
                sortable: true,
                resizable: true,
                filter: true,
              }}
              onRowClicked={handleClick}
              columnDefs={colDefs}
            />
          ) : (
            <div id="AssessmentLoadingContainer">
              <ReactLoading
                type={"spin"}
                color={"rgb(227, 230, 255)"}
                height={"25%"}
                width={"25%"}
              />
            </div>
          )}
        </div>
      </div>
      <Switch location={location} key={location.pathname}></Switch>
    </React.Fragment>
  );
};

export default AssessmentsManagement;
