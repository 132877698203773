import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../../../Context";
import { useOktaAuth } from "@okta/okta-react";
import { ProgressCircleContainer } from "../../../../..";
import {
  publishAssessorComment,
  getAssessmentBpq,
} from "../../../../../GlobalResources/ApiCalls";
import { assessmentStages } from "../../../../../GlobalResources/Constants";

export const RepublishAllComments: React.FC<any> = (props: any) => {
  const [isRepublishing, setIsRepublishing] = useState(false);
  const [gettingAssessmentBpq, setGettingAssessmentBpq] = useState(false);
  const { globalState } = useContext(AppContext);
  const { authState } = useOktaAuth();

  const republishAllComments = async (e: any) => {
    e.preventDefault();
    if (
      window.confirm(
        "Are you sure you want to republish all comments?\nAny unfinished or out-of-scope questions will be ignored.\nPlease allow all comments to be republished before leaving this page."
      )
    ) {
      try {
        //Re-pull current assessment bpq for all up to date data
        setGettingAssessmentBpq(true);
        const bpqAndMapping = await getAssessmentBpq(globalState, authState);
        const assessmentBpq = bpqAndMapping?.assessmentBpq;
        setGettingAssessmentBpq(false);

        //Start republising event
        setIsRepublishing(true);
        sessionStorage.setItem("isRepublishing", "true");
        window.dispatchEvent(new Event("storage"));

        //Filter questions where answer exists and are in scope
        const questions = assessmentBpq.filter(
          (row: any) => row.answer && !row.isDisabled
        );

        for (const question of questions.values()) {
          //Setup publish data
          let checklist: Array<any> = [];
          let validationSteps = null;
          let revisedSummary = null;
          let revisedComment = null;
          let evidenceReferenced: object = {};
          let assessorWorkSteps: Array<any> = [
            question?.review?.fields?.filter(
              (field: any) => field["name"] === "assessorWorkSteps"
            )[0]["options"][0],
          ];
          let revisedMainAnswer = question?.answer?.question?.mainAnswer?.value;
          let iaaS = null;
          let paaS = null;
          let saaS = null;
          let customerResponsibility = null;

          //Convert checklist items from object to array
          if (question.answer.checklist) {
            checklist = Object.keys(question.answer.checklist).map(
              (serialId: any) => {
                let evidence = null;
                if (question.answer.checklist[serialId].evidence) {
                  evidence = {
                    artifacts:
                      question.answer.checklist[serialId].evidence.artifacts,
                  };
                }
                return {
                  serialId: serialId,
                  mainAnswer:
                    question?.answer?.checklist[serialId]?.mainAnswer?.value,
                  evidence: evidence,
                  additionalComments:
                    question?.answer?.checklist[serialId]?.additionalComments
                      ?.value,
                };
              }
            );
          }

          //Add remaining comment data
          validationSteps = question?.answer?.review?.validationSteps?.value;
          revisedSummary = question?.answer?.review?.revisedSummary?.value;
          revisedComment = question?.answer?.review?.revisedComment?.value;
          evidenceReferenced = {
            artifacts: question?.answer?.review?.evidenceReferenced?.artifacts,
          };
          assessorWorkSteps =
            question?.answer?.review?.assessorWorkSteps?.value &&
            question.answer.review.assessorWorkSteps.value.length > 0
              ? question.answer.review.assessorWorkSteps.value
              : assessorWorkSteps;
          revisedMainAnswer =
            question?.answer?.review?.revisedMainAnswer?.value &&
            question.answer.review.revisedMainAnswer.value.value !== ""
              ? question.answer.review.revisedMainAnswer.value
              : revisedMainAnswer;
          iaaS = question?.answer?.review?.iaaS?.value;
          paaS = question?.answer?.review?.paaS?.value;
          saaS = question?.answer?.review?.saaS?.value;
          customerResponsibility =
            question?.answer?.review?.customerResponsibility?.value;

          //Compile payload
          const payload = {
            assessorWorkSteps: assessorWorkSteps,
            checklist: checklist,
            evidenceReferenced: evidenceReferenced,
            questionId: question.questionId,
            serialId: question.serialId,
            revisedComment: revisedComment,
            revisedMainAnswer: revisedMainAnswer,
            revisedSummary: revisedSummary,
            validationSteps: validationSteps,
            iaaS: iaaS,
            paaS: paaS,
            saaS: saaS,
            customerResponsibility: customerResponsibility,
          };

          //Call publish api
          await publishAssessorComment(globalState, payload, question["label"]);
        }

        alert(`All applicable comments have been republished`);
        setIsRepublishing(false);
        sessionStorage.setItem("isRepublishing", "false");
        window.dispatchEvent(new Event("storage"));
      } catch (err) {
        globalState.featureToggle.debugLogging && console.error(err);
        globalState.featureToggle.debugLogging && console.log(err.response);
        setIsRepublishing(false);
        sessionStorage.setItem("isRepublishing", "false");
        window.dispatchEvent(new Event("storage"));
        alert(globalState.config.errorMessage);
      }
    }
  };

  useEffect(() => {
    //On component mount, check session storage if republishing is still occurring
    function checkIsRepublishing() {
      const isRepublishing = sessionStorage.getItem("isRepublishing");

      if (isRepublishing === "true") {
        setIsRepublishing(true);
      } else {
        setIsRepublishing(false);
      }
    }

    window.addEventListener("storage", () => {
      checkIsRepublishing();
    });

    window.dispatchEvent(new Event("storage"));

    return () => {
      //On component unmount, set republishing to false in session storage
      sessionStorage.setItem("isRepublishing", "false");
      window.removeEventListener("storage", checkIsRepublishing);
    };
  }, []);

  return (
    <React.Fragment>
      {/* Check if user has permission and is able to publish comments at the current assessment stage */}
      {globalState.permissions[
        "Assessment.CommentBuilder.Drawer.GeneratedComment.Edit"
      ] &&
      globalState.permissions[
        "Assessment.CommentBuilder.Drawer.GeneratedComment.Edit"
      ].stages[assessmentStages[globalState.assessmentData.statusId]] &&
      globalState.featureToggle.republishAllComments &&
      globalState.assessmentBpq ? (
        <button onClick={republishAllComments} value="republishAllComments">
          Republish All Comments
        </button>
      ) : (
        <button className="disabled" disabled={true}>
          Republish All Comments
        </button>
      )}

      {(isRepublishing || gettingAssessmentBpq) && (
        <ProgressCircleContainer
          text={
            isRepublishing
              ? `Comments are currently being republished, please wait...`
              : `Retrieving updated assessment comment data`
          }
        />
      )}
    </React.Fragment>
  );
};

export default RepublishAllComments;
