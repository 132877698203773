import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as TrusightLogo } from "../../../../Static/logo-trusight.svg";
import { useOktaAuth } from "@okta/okta-react";
import { AppContext } from "../../../../Context";

import {
  AppBar,
  Toolbar,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Dehaze, Assessment, AttachMoney } from "@mui/icons-material";

import { useStyles } from "./NavbarStyles";

interface IListItem {
  listIcon: any;
  listText: string;
  link: string;
}

export const Navbar: React.FC<any> = (props) => {
  const [menuItems, setMenuItems] = useState<Array<IListItem>>([]);
  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();
  const logOut = () => oktaAuth.signOut();
  const btnLogic = logOut;
  const { globalState } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setMobileMenu(true);
  };

  const handleClose = () => {
    setMobileMenu(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    const menuItemsToAdd = new Array<IListItem>();
    menuItemsToAdd.push({
      listIcon: <Assessment />,
      listText: "Assessments",
      link: "/assessmentsmanagement",
    });
    globalState.permissions["Assessment.Orders.Cancel.Full"] &&
      globalState.featureToggle.cancelOrder &&
      menuItemsToAdd.push({
        listIcon: <AttachMoney />,
        listText: "Orders Management",
        link: "/ordersmanagement",
      });
    setMenuItems(menuItemsToAdd);
  }, []);

  return (
    <React.Fragment>
      <AppBar position="relative" className={classes.appBarStyles}>
        <Toolbar className={classes.toolbarStyles}>
          <div className={classes.toggleSlider}>
            <IconButton
              aria-controls="simple-menu"
              className={classes.toggleSlider}
              onClick={handleClick}
            >
              <ListItemIcon className={classes.toggleButton}>
                <Dehaze />
              </ListItemIcon>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              PaperProps={{
                style: {
                  backgroundColor: "black",
                  backdropFilter: "blur(5px)",
                  borderRadius: "10px",
                },
              }}
              keepMounted
              open={mobileMenu}
              onClose={handleClose}
            >
              {menuItems.map((item, idx) => {
                return (
                  <MenuItem key={idx} onClick={handleClose}>
                    <NavLink
                      exact
                      className={classes.menuLink}
                      activeClassName={classes.sidebarActive}
                      to={item.link}
                    >
                      {/* <div className={classes.listItemRow}> */}
                      <ListItem>
                        <ListItemIcon className={classes.listItem}>
                          {item.listIcon}
                        </ListItemIcon>
                        <ListItemText
                          className={classes.listItem}
                          primary={item.listText}
                        />
                      </ListItem>
                      {/* </div> */}
                    </NavLink>
                  </MenuItem>
                );
              })}
            </Menu>
          </div>

          <div>
            <h2 className={classes.logoContainer}>
              <TrusightLogo className={classes.logo} />
            </h2>
          </div>
          <div>
            <button onClick={btnLogic}>Logout</button>
          </div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
