export const assessmentStageNames: any = {
  48: "14. Product Creation",
  49: "Assessment Complete",
  50: "SD. Scale-Down Assessment",
  54: "04. Qualification",
  55: "05. BPQ Sent",
  56: "06. BPQ Submitted",
  57: "07. Pre-Work in Progress",
  58: "08. Post-Work in Progress",
  59: "09. Observation Window",
  60: "10. QC Assignment",
  61: "11. QC in Progress",
  62: "12. QC Response",
  63: "13. QC Finalization",
  7014: "00. Unqualified",
  7015: "01. Qualified",
  7016: "02. Engaged",
  7017: "03. Committed",
};
