import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import "./AssessmentOverlay.css";
import ArrowBack from "@mui/icons-material/ArrowBack";
import AssessmentOptions from "./OptionsComponent/AssessmentOptions";
import AssessmentData from "./DataComponent/AssessmentData";
import { AppContext } from "../../../../Context";
import { useOktaAuth } from "@okta/okta-react";
import { getAssessmentData } from "../../../GlobalResources/ApiCalls";

export const AssessmentOverlay: React.FC<any> = (props: any) => {
  const { globalState, setGlobalState } = useContext(AppContext);
  const { authState } = useOktaAuth();
  const selectedAssessmentId = window.location.pathname.slice(23);
  const history = useHistory();
  const name = globalState?.assessmentData?.name;

  const handleClick = () => {
    setGlobalState({
      ...globalState,
      selectedAssessmentId: null,
      assessmentBpq: null,
      assessmentData: null,
      questionsMapping: null,
      assessmentUsers: null,
      assignableUsers: null,
      assignableThirdPartyUsers: null,
    });
    history.push(`/assessmentsmanagement`);
  };

  useEffect(() => {
    let unMounted = false;
    const onLoad = async () => {
      const assessmentData = await getAssessmentData(
        { ...globalState, selectedAssessmentId },
        authState
      );
      !unMounted &&
        setGlobalState({
          ...globalState,
          assessmentData,
          selectedAssessmentId,
        });
    };

    onLoad();

    return () => {
      unMounted = true;
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        globalState,
        setGlobalState,
      }}
    >
      <div id="AssessmentOverlay">
        <h1>{name || "Loading Assessment Data"}</h1>
        <div id="AssessmentOverlayBox">
          <div id="InformationAndOptionsContainer">
            <div id="Information">
              <button title="Back to Assessments" onClick={handleClick}>
                <ArrowBack />
              </button>
              {globalState.assessmentData && <AssessmentData />}
            </div>
            <div id="Options">
              {globalState.assessmentData && <AssessmentOptions />}
            </div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default AssessmentOverlay;
