import axios from "axios";
import { IThirdPartyResponsePayload } from "../Interfaces";

export const answerThirdPartyResponse = async (
  globalState: any,
  authState: any,
  payload: IThirdPartyResponsePayload
) => {
  try {
    sessionStorage.setItem("isImporting", "true");
    window.dispatchEvent(new Event("storage"));
    await axios.put(
      `${globalState.config.portalApiUrl}/assessments/${globalState.selectedAssessmentId}/bpq`,
      [payload],
      {
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
  } catch (err) {
    // Handle Error Here
    globalState.featureToggle.debugLogging && console.error(err);
    globalState.featureToggle.debugLogging && console.log(err.response);
    alert(globalState.config.errorMessage);
  }
};
