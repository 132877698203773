import axios from "axios";

export const downloadExport = async (globalState: any, authState: any) => {
    try {
        const res = await axios({
            url: `${globalState.config.portalApiUrl}/assessments/${globalState.selectedAssessmentId}/bpq/export-excel`,
            headers: {
              authorization: `Bearer ${authState?.accessToken?.accessToken}`,
            },
            responseType: "blob",
          });
        
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export-excel.xlsx");
          document.body.appendChild(link);
          link.click();
    } catch (err) {
        // Handle Error Here
        globalState.featureToggle.debugLogging && console.error(err);
        globalState.featureToggle.debugLogging && console.log(err.response);
        alert(globalState.config.errorMessage);
      }
}

