import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "auto"
  },
  logo:{
    height: "70%",
    width: "70%",
    margin: "auto"
  },
  Nav: {
    position: "relative",
  },
  appBarStyles: {
    backgroundColor: "#232a3f",
    height: "65px",
  },
  toolbarStyles: {
    display: "flex",
    justifyContent: "space-between",
    height: "65px",
    "& button": {
      width: "100px",
      backgroundColor: "rgba(35, 42, 63, 1)",
      padding: "8px",
      color: "white",
      border: "0px",
    },
    "& button:hover": {
      backgroundColor: "rgb(68, 81, 117)",
      cursor: "pointer"
    }
  },
  listItemRow:{
    width: "100%",
    display: "flex",
    position: "relative",
    paddingTop: "8px",
    paddingBottom: "8px",
  },

  listItem: {
    color: "rgb(240, 240, 240)",
  },
  toggleSlider: {
    width: "100px",
    color: "rgba(255, 255, 255, 1)",
  },
  menuLink: {
    textDecoration: "none",
  },
  avatar: {
    display: "block",
    margin: "1rem auto",
    width: theme.spacing(16),
    height: theme.spacing(16),
  },

  navbarContainer: {
    display: "none",
    justifyContent: "space-between",
    "& a": {
      color: "rgb(240, 240, 240)",
      textDecoration: "none",
      margin: "0 10px",
      borderBottom: "1px solid transparent",
      "&:hover": {
        borderBottom: "1px solid rgb(240, 240, 240)",
      },
      "& span": {
        fontSize: "1.3em",
      },
    },
  },
  moreInfoContainer: {
    marginLeft: "auto",
  },
  toggleButton: {
    color: "rgb(240, 240, 240)",
    minWidth: "0px",
    margin: "auto",
    "& span": {
      fontSize: "0.8em",
      color: "rgb(240, 240, 240)",
    },
  },
  sidebarActive: {
    borderBottom: "1px solid rgb(240, 240, 240)!important",
  },
  navbarActive: {
    borderBottom: "1px solid rgb(240, 240, 240)!important",
  },
}));
