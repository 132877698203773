import axios from "axios";

export const unassignUserFromAssessment = async (
  globalState: any,
  authState: any,
  userId: string
) => {
  try {
    await axios.delete(
      `${globalState.config.portalApiUrl}/assessments/${globalState.selectedAssessmentId}/users/${userId}`,
      {
        headers: {
          authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      }
    );
  } catch (err) {
    // Handle Error Here
    globalState.featureToggle.debugLogging && console.error(err);
    globalState.featureToggle.debugLogging && console.log(err.response);
    alert(globalState.config.errorMessage);
  }
};
