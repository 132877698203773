import axios from "axios";

export const assignUserToAssessment = async (
  globalState: any,
  authState: any,
  user: any
) => {
  try {
    await axios.put(
      `${globalState.config.portalApiUrl}/assessments/${
        globalState.selectedAssessmentId
      }/users/${
        user.userEmail.includes("@trusightsolutions.com")
          ? "trusight"
          : "thirdparty"
      }/${user.userId}`,
      {},
      {
        headers: {
          authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      }
    );
  } catch (err) {
    // Handle Error Here
    globalState.featureToggle.debugLogging && console.error(err);
    globalState.featureToggle.debugLogging && console.log(err.response);
    alert(globalState.config.errorMessage);
  }
};
