import React, { useContext } from "react";
import "./AssessmentData.css";
import Lodash from "lodash";
import { AppContext } from "../../../../../Context";
import {
  assessmentStageNames,
  assessmentTypes,
} from "../../../../GlobalResources/Constants";

export const AssessmentData: React.FC<any> = (props) => {
  const { globalState } = useContext(AppContext);
  const data = globalState.assessmentData;

  let {
    id,
    organizationName,
    statusId,
    assessmentTypeId,
    bpqVersion,
    availableToReleaseDate,
    bpqDueDate,
    fieldWorkStartDate,
    fieldWorkEndDate,
    observationStartDate,
    observationDueDate,
    qcProgressDate,
    qcResponseDate,
    qcCompleteDate,
    riskLeadName,
    towerLeadName,
    qualityControlName,
    thirdPartyAdminName,
    thirdPartyMainAddress,
  } = data;

  const listData: any = {
    id,
    organizationName,
    assessmentStatus: assessmentStageNames[statusId],
    assessmentType: assessmentTypes[assessmentTypeId],
    bpqVersion,
    availableToReleaseDate: availableToReleaseDate?.substring(
      0,
      availableToReleaseDate.indexOf("T")
    ),
    bpqDueDate: bpqDueDate?.substring(0, bpqDueDate.indexOf("T")),
    fieldWorkStartDate: fieldWorkStartDate?.substring(
      0,
      fieldWorkStartDate.indexOf("T")
    ),
    fieldWorkEndDate: fieldWorkEndDate?.substring(
      0,
      fieldWorkEndDate.indexOf("T")
    ),
    observationStartDate: observationStartDate?.substring(
      0,
      observationStartDate.indexOf("T")
    ),
    observationDueDate: observationDueDate?.substring(
      0,
      observationDueDate.indexOf("T")
    ),
    qcProgressDate: qcProgressDate?.substring(0, qcProgressDate.indexOf("T")),
    qcResponseDate: qcResponseDate?.substring(0, qcResponseDate.indexOf("T")),
    qcCompleteDate: qcCompleteDate?.substring(0, qcCompleteDate.indexOf("T")),
    riskLeadName,
    towerLeadName,
    qualityControlName,
    thirdPartyAdminName,
    thirdPartyMainAddress: thirdPartyMainAddress
      ? `${
          thirdPartyMainAddress["street1"]
            ? thirdPartyMainAddress["street1"] + ", "
            : ""
        } ${
          thirdPartyMainAddress["street2"]
            ? thirdPartyMainAddress["street2"] + ", "
            : ""
        }${
          thirdPartyMainAddress["city"]
            ? thirdPartyMainAddress["city"] + ", "
            : ""
        }${
          thirdPartyMainAddress["state"]
            ? thirdPartyMainAddress["state"] + ", "
            : ""
        }${
          thirdPartyMainAddress["zipCode"]
            ? thirdPartyMainAddress["zipCode"] + ", "
            : ""
        }${
          thirdPartyMainAddress["country"]
            ? thirdPartyMainAddress["country"]
            : ""
        }`
      : "None",
  };

  const keys = data && Object.keys(listData);

  return (
    <div id="AssessmentData">
      {data &&
        keys.map((key: string, index: number) => {
          return (
            <React.Fragment key={keys[index]}>
              <div className={index % 2 !== 0 ? "row" : "row altColor"}>
                <span className="ItemKey">{Lodash.startCase(key)}: </span>
                <span>
                  {listData[key] === false
                    ? "No"
                    : listData[key] === true
                    ? "Yes"
                    : listData[key]}
                </span>
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default AssessmentData;
