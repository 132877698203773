import React from "react";
import ReactLoading from "react-loading";
import "./ProgressCircleContainer.css";

export const ProgressCircleContainer: React.FC<any> = (props: any) => {
  return (
    <div id="ProgressCircleContainer">
      <div id="ProgressCircleBox">
        <div id="ProgressCircleBar">
          <ReactLoading
            type={"spin"}
            color={"rgb(0, 102, 204)"}
            height={"50%"}
            width={"50%"}
          />
        </div>

        {props.text && (
          <div id="ProgressCircleText">
            <h1>{props.text}</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressCircleContainer;
