import axios from "axios";
import { IAssessorCommentPayload } from "../Interfaces";

export const publishAssessorComment = async (
  globalState: any,
  payload: IAssessorCommentPayload,
  questionLabel: string
) => {
  try {
    sessionStorage.setItem("isRepublishing", "true");
    window.dispatchEvent(new Event("storage"));
    await axios.post(
      `${globalState.config.portalApiUrl}/assessments/${globalState.selectedAssessmentId}/comment-builder/publish`,
      payload,
      {
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (err) {
    // Handle Error Here
    globalState.featureToggle.debugLogging && console.error(err);
    globalState.featureToggle.debugLogging && console.log(err.response);
    globalState.featureToggle.debugLogging &&
      console.log(`The error occurred in question ${payload.serialId}`);
    alert(
      globalState.config.errorMessage +
        `\nThe error occurred in question: ${questionLabel}`
    );
  }
};
