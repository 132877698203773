import axios from "axios";

export const generateCache = async (globalState: any, authState: any) => {
  if (
    globalState.permissions["Assessment.UpdateProductGenerationDataCache"] 
  ) {
    try {
      await axios.put(
        `${globalState.config.portalApiUrl}/assessments/${globalState.selectedAssessmentId}/productgenerationdatacache`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
            "Content-Type": "application/json"
          },
        }
      ).then(function(response) {
        if (response.status===204)
        {
            window.confirm(
                "Cache regenerated successfully!"
              )
        }

      });
    } catch (err) {
      globalState.featureToggle.debugLogging && console.error(err);
      globalState.featureToggle.debugLogging && console.log(err.response);
      alert(globalState.config.errorMessage);
    }
  } else {
    return;
  }
};