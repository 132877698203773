import axios from "axios";
import { IUser } from "../Interfaces";

export const getAssignableUsers = async (globalState: any, authState: any) => {
  try {
    let res =
      globalState.permissions["Assessment.People.Edit.TS"] &&
      (await axios.get(
        `${globalState.config.portalApiUrl}/assessments/${globalState.selectedAssessmentId}/users/trusight/available-to-assign`,
        {
          headers: {
            authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }
      ));

    const trusightUsers =
      globalState.permissions["Assessment.People.Edit.TS"] && res
        ? res.data.map((user: IUser) => {
            return {
              label: `${user.fullName} - ${user.email} - ${user.roles}`,
              value: {
                userId: user.userId,
                userEmail: user.email,
                type: "Assign Assessment Access",
              },
            };
          })
        : [];

    res =
      globalState.permissions["Assessment.People.Edit.TP"] &&
      (await axios.get(
        `${globalState.config.portalApiUrl}/assessments/${globalState.selectedAssessmentId}/users/thirdparty/available-to-assign`,
        {
          headers: {
            authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }
      ));

    const thirdPartyUsers =
      globalState.permissions["Assessment.People.Edit.TP"] && res
        ? res.data.map((user: IUser) => {
            return {
              label: `${user.fullName} - ${user.email} - ${user.roles}`,
              value: {
                userId: user.userId,
                userEmail: user.email,
                type: "Assign Assessment Access",
              },
            };
          })
        : [];

    return [...trusightUsers, ...thirdPartyUsers];
  } catch (err) {
    // Handle Error Here
    globalState.featureToggle.debugLogging && console.error(err);
    globalState.featureToggle.debugLogging && console.log(err.response);
    alert(globalState.config.errorMessage);
  }
};
