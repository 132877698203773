import React, { useState, useContext} from "react";
import { AppContext } from "../../../../../../Context";
import { useOktaAuth } from "@okta/okta-react";
import { generateCache } from "../../../../../GlobalResources/ApiCalls";
import { assessmentStages } from "../../../../../GlobalResources/Constants";

export const GenerateCache: React.FC<any> = () => {
  const { globalState } = useContext(AppContext);
  const { authState } = useOktaAuth();
  const [isLoading, setIsLoading]: any = useState(false);

  const generateProductCache = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      window.confirm(
        "Are you sure you want to regenerate the cache?\nAll cached values will be updated if changed since initial caching."
      )
    )
    {
      try {
        await generateCache(globalState,authState)
        setIsLoading(false);
      } catch (err) {
        // Handle Error Here
        if (err.response.status === 403) {
          alert(
            "You have no permission to generate the cache."
          );
          setIsLoading(false);
          globalState.featureToggle.debugLogging && console.error(err);
          globalState.featureToggle.debugLogging && console.log(err.response);
        } else {
          alert(globalState.config.errorMessage);
          setIsLoading(false);
          globalState.featureToggle.debugLogging && console.error(err);
          globalState.featureToggle.debugLogging && console.log(err.response);
        }
      }
    }
    else
    {
      setIsLoading(false)
    }
  };

  return (
      globalState.permissions["Assessment.UpdateProductGenerationDataCache"] && 
      assessmentStages[globalState.assessmentData.statusId] === 14 &&
      isLoading === false ? (
            <button onClick={generateProductCache}>Generate Cache</button>
          ) : (<button className="disabled" disabled={true}>Generate Cache</button>)
    );
};
export default GenerateCache;
